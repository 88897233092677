<template>
  <center>
    <h1>利用中です</h1>
    <button id="logout" v-on:click="logout" class="btn btn-danger btn-lg" style="width:160px;height:50px;font-size:15px;">再入室</button>
  </center>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
    };
  },
  mounted() {
    
  },
  methods: {
    logout() {
      this.logoutSesion();
      // this.$cookies.remove("token");
      // this.$cookies.remove("usertoken");
      this.$router.push("/login");
    },
    logoutSesion(){
      var token = this.$cookies.get("token");
      // if(token!= null || token != ''){
      //   this.delAuthen(token);
      // }
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
    },
    delAuthen(tokenid) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/delAuthen`, {
          token: tokenid,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data == "success") {
              this.flg = true;
            }
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 5em;
}
</style>